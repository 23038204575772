<template>
	<div class="relative flex py-10">
		<svg
			v-if="animate"
			viewBox="0 0 504 442"
			fill="none"
			class="absolute inset-0 w-full h-full"
		>
			<g
				v-if="!hideAnimation"
				id="Circles_1"
				:class="{ 'stroke-animate' : animated }"
				:style="{ '--animation-delay' : animationDelay }"
			>
				<path
					id="Left_Ellipse_Inner"
					d="M151.573 42.9982C90.24 78.6501 49.0085 145.042 49.0085 221.057C49.0085 297.072 90.24 363.464 151.573 399.116"
					stroke="#900C3F"
					stroke-width="10"
				/>
				<path
					id="Left_Ellipse_Middle"
					d="M142.171 416.315C74.7267 377.327 29.3504 304.455 29.3504 220.991C29.3504 137.705 74.5351 64.9643 141.744 25.9156"
					stroke="#C70039"
					stroke-width="6"
				/>
				<path
					id="Left_Ellipse_Outer"
					d="M128.068 2C52.6848 45.7746 2 127.348 2 220.751C2 314.331 52.8764 396.036 128.496 439.75"
					stroke="#FF5733"
					stroke-width="4"
				/>
			</g>
			<g
				v-if="!hideAnimation"
				id="Circles_2"
				:class="{ 'stroke-animate' : animated }"
				:style="{ '--animation-delay' : animationDelay }"
			>
				<path
					id="Right_Ellipse_Inner"
					d="M352.427 398.753C413.76 363.101 454.991 296.709 454.991 220.694C454.991 144.679 413.76 78.287 352.427 42.635"
					stroke="#900C3F"
					stroke-width="10"
				/>
				<path
					id="Right_Ellipse_Middle"
					d="M361.829 25.4362C429.273 64.4244 474.65 137.296 474.65 220.76C474.65 304.046 429.465 376.787 362.256 415.835"
					stroke="#C70039"
					stroke-width="6"
				/>
				<path
					id="Right_Ellipse_Outer"
					d="M375.932 439.751C451.315 395.976 502 314.403 502 221C502 127.42 451.124 45.7147 375.504 2.00056"
					stroke="#FF5733"
					stroke-width="4"
					class="delay-1000"
				/>
			</g>
		</svg>
		<NuxtImg
			:id="id"
			:class="[
				'mx-auto w-[76%]',
				{ 'rounded-full aspect-1 object-cover' : !hideAnimation }
			]"
			:src="src"
			:srcset="srcset"
			:alt="alt"
			width="504"
			height="504"
			:loading="loading"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, onMounted, onBeforeUnmount } from 'vue'
import type { Image } from '@/types/image'

const props = defineProps({
	image: {
		type: Object as PropType<Image>,
		required: true
	},
	animated: {
		type: Boolean,
		default: true
	},
	hideAnimation: {
		type: Boolean,
		default: false
	},
	lazyLoad: {
		type: Boolean,
		default: false
	}
})
const { image, animated, lazyLoad } = toRefs(props)
const observer = ref<IntersectionObserver | null>(null)
const observed = ref(false)
const animationDelay = ref(200)
const src = computed(() => image.value?.src)
const srcset = computed(() => image.value?.srcset)
const alt = computed(() => image.value?.alt)
const id = computed(() => image.value?.id || 'transmit-image')

const hasIO = computed(() => window && 'IntersectionObserver' in window)

const animate = computed(() => {
	return animated.value ? observed.value : true
})

function startObserver () {
	observer.value = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					observed.value = true
					observer.value?.unobserve(entry.target)
				}
			})
		},
		{
			rootMargin: '0px',
			threshold: 0.5
		}
	)
	const el = document.getElementById(id.value)
	if (el) {
		observer.value?.observe(el)
	}
}

const loading = computed(() => {
	return lazyLoad.value ? 'lazy' : undefined
})

onMounted(() => {
	if (hasIO.value) {
		startObserver() // start intersection observer to trigger animation (client side only)
	}
})

// disconnect observer on unmount
onBeforeUnmount(() => {
	observer.value?.disconnect()
})
</script>
